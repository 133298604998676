import React, { useState, useLayoutEffect } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const Donate = () => {
  const [loading, setLoading] = useState(true)
  const [amount, setAmount] = useState("50,00")

  useLayoutEffect(() => {
    document.querySelector("iframe").onload = () => {
      setLoading(false)
    }
    /* if (!loading) {
      const iframe = document.querySelector("iframe")
      const input =
        iframe.contentWindow.document.querySelector("#spende_betrag")
      console.log(input)
    } */
  }, [amount, loading])

  return (
    <Layout>
      <iframe
        title="Donate"
        src="https://em.altruja.de/neues-spendenformular-3852?_altrj_ckbn=i"
        width="100%"
        height="0"
        id="234kjh"
        name="234kjh"
        frameBorder="0"
        scrolling="no"
        style={{
          position: "relative",
          width: "100%",
          margin: "0px",
          padding: "0px",
          border: "none",
          height: "1787px",
        }}
      />
    </Layout>
  )
}

export default Donate
